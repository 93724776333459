import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'item' ]

  handleDragStart(e) {
    window.draggableId = this.itemTarget.id
    this.itemTarget.classList.add("text-green-500")
    const mousePositionWithinDraggable = mousePositionElement(e, false)
    const clone = this.createClone(this.itemTarget)
    document.querySelector('body').append(clone)
    e.dataTransfer.setDragImage(clone, mousePositionWithinDraggable.x, mousePositionWithinDraggable.y)
    window.forceMousePos = mousePositionWithinDraggable
    this.itemTarget.classList.add("opacity-0")
  }

  handleDragend(e) {
    this.itemTarget.classList.remove("text-green-500")
    this.itemTarget.classList.remove("hidden")
    this.itemTarget.classList.remove("opacity-0")
    document.getElementById(`clone-${this.itemTarget.id}`).remove()
  }

  // private

  createClone(target) {
    const clone = target.cloneNode()
    clone.style.position = 'absolute'
    clone.style.top = "-10000px"
    clone.style.width = target.width
    clone.id = `clone-${target.id}`
    clone.innerHTML = target.innerHTML

    return clone
  }
}
