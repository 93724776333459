import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'item', 'form', "cancel" ]

  showForm(e) {
    e.preventDefault()
    this.itemTarget.classList.add("hidden")
    this.formTarget.classList.remove("hidden")
  }

  hideForm(e) {
    e.preventDefault()
    this.itemTarget.classList.remove("hidden")
    this.formTarget.classList.add("hidden")
  }

  submitForm(e) {
    Rails.fire(this.formTarget, 'submit')
  }
}
