import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'dropzone', 'item' ]

  connect() {
    if (this.data.get("type") !== "AffinitizedGroup" || this.data.get("type") !== "LeftoverGroup") {
      for (const target of this.itemTargets) {
        target.dataset.offsetLeft = target.offsetLeft
        target.dataset.offsetTop = target.offsetTop
      }

      for (const target of this.itemTargets) {
        target.classList.add("absolute")
        target.style.left = `${target.dataset.offsetLeft}px`
        target.style.top = `${target.dataset.offsetTop}px`
      }
    }
  }

  handleDragOver(e) {
    e.preventDefault()

    this.dropzoneTarget.classList.remove("bg-pattern")
    if (this.isValidDroppable(e)) {
      this.dropzoneTarget.classList.add("bg-green-100")
    } else {
      this.dropzoneTarget.classList.add("bg-red-100")
      this.dropzoneTarget.classList.remove("border-blue-500")
      this.dropzoneTarget.classList.add("border-red-500")
    }
  }

  handleDragEnter(e) {
    e.preventDefault()

    this.dropzoneTarget.classList.remove("bg-pattern")
    if (this.isValidDroppable(e)) {
      this.dropzoneTarget.classList.add("bg-green-100")
    } else {
      this.dropzoneTarget.classList.add("bg-red-100")
    }
  }

  handleDragLeave(e) {
    this.dropzoneTarget.classList.remove('bg-green-100')
    this.dropzoneTarget.classList.remove('bg-red-100')
    this.dropzoneTarget.classList.remove('border-red-500')

    if (this.data.get("type") == "PileOverlay" || this.data.get("overlayOpen") == "true") {
      this.dropzoneTarget.classList.add("bg-pattern")
      this.dropzoneTarget.classList.add('border-blue-500')
    }
  }

  handleDrop(e) {
    e.preventDefault()
    if (!this.isValidDroppable(e)) {
      this.dropzoneTarget.classList.remove('bg-red-100')
      this.dropzoneTarget.classList.remove('border-red-500')

      if (this.data.get("type") == "PileOverlay" || this.data.get("overlayOpen") == "true") {
        this.dropzoneTarget.classList.add("border-blue-500")
        this.dropzoneTarget.classList.add('bg-pattern')
      }
      return false
    }

    var draggable = document.getElementById(window.draggableId)

    const mousePos = mousePositionElement(e)
    draggable.classList.add("absolute")
    draggable.style.zIndex = 1000 // overwrite the current z-index so that the draggable is on top before re-rendering the partial
    var yPosition = draggable.style.top = `${mousePos.y - window.forceMousePos.y}px`
    var xPosition = draggable.style.left = `${mousePos.x - window.forceMousePos.x}px`
    this.dropzoneTarget.append(draggable)
    this.dropzoneTarget.classList.remove('bg-green-100')
    if (this.data.get("type") == "PileOverlay") {
      this.dropzoneTarget.classList.add("bg-pattern")
    }

    if (this.data.get("type") == "AffinitizedGroup" || this.data.get("type") == "LeftoverGroup") {
      var params = {
        id: draggable.dataset.draggableId,
        draggable_type: draggable.dataset.draggableType,
        current_droppable_id: draggable.dataset.currentDroppableId,
        current_droppable_type: draggable.dataset.currentDroppableType,
        droppable_id: this.data.get("id"),
        droppable_type: this.data.get("type"),
        pile_overlay_id: new URLSearchParams(window.location.search).get("pile_overlay_id"),
        x: xPosition,
        y: yPosition,
      }
      Rails.ajax({
        type: 'POST',
        url: "/force_moves",
        data: new URLSearchParams(params).toString()
      })
    }
  }

  isValidDroppable(e) {
    var newDroppableType = this.data.get("type")
    var currentDroppableType = document.getElementById(window.draggableId).dataset.currentDroppableType

    return newDroppableType == "AffinitizedGroup" || newDroppableType == "LeftoverGroup" || newDroppableType == currentDroppableType
  }
}
