import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["toggle", "collapse"]

  toggle(e) {
    e.preventDefault()


    const currentText = this.toggleTarget.innerText
    const nextText = this.textOptions().find(option => option !== currentText)

    this.toggleTarget.innerText = nextText
    this.collapseTarget.classList.toggle("hidden")
  }

  textOptions() {
    return [
      this.toggleTarget.dataset.expandText,
      this.toggleTarget.dataset.collapseText,
    ]
  }
}